import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const eventCarouselBlocks = document.querySelectorAll('.events-carousel');

  if (0 < eventCarouselBlocks.length) {
    eventCarouselBlocks.forEach((eventCarouselBlock) => {
      new Swiper(
        eventCarouselBlock.querySelector('.events-carousel__carousel'),
        {
          slidesPerView: 1.2,
          spaceBetween: 16,
          loop: false,
          centerInsufficientSlides: true,

          breakpoints: {
            768: {
              slidesPerView: 2.4,
              spaceBetween: 20,
            },
            1080: {
              slidesPerView: 3,
              spaceBetween: 24,
            },
            1440: {
              slidesPerView: 3,
              spaceBetween: 44,
            },
          },
        }
      );
    });
  }
});
